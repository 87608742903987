<template>
  <div id="scroll-container">
    <div class="background">
      <div class="block">
        <el-link href="https://www.tianzi.info/" target="_blank" type="primary">天紫导航</el-link>
        <el-link href="https://blog.tianzi.info/" target="_blank" type="primary"
          style="margin-left: 10px;">天紫论坛</el-link>
        <el-link href="https://img.tianzi.info/" target="_blank" type="primary"
          style="margin-left: 10px;">天紫图床</el-link>
        <el-link href="https://jz.tianzi.info/" target="_blank" type="primary" style="margin-left: 10px;">天紫记账</el-link>
        <el-tooltip placement="top">
          <template slot="content">
            <img src="~@/assets/er.jpg" alt="WeChat" style="width: 20vw; height: auto;" />
          </template>
          <el-link type="primary" style="margin-left: 10px;">联系天紫</el-link>
        </el-tooltip>
        <el-tooltip placement="top">
          <template slot="content">
            <img src="~@/assets/sk.png" alt="WeChat" style="width: 20vw; height: auto;" />
          </template>
          <el-link type="danger" style="margin-left: 50px;">是不是应该整点广告？ - -!开玩笑的。- -。求赞助~</el-link>
        </el-tooltip>
      </div>
      <el-dialog title="公告" :visible.sync="dialogVisible" width="80%" center :show-close="false"
        :close-on-click-modal="false" :close-on-press-escape="false">
        <p class="indent-1-tabs">本公告只会在首次进入网站时进行提示，请务必仔细阅读。</p>
        <p class="indent-2-tabs">1.本站所有解析接口均来自互联网，旨在交流学习使用。请勿相信页面内(包括但不限于)任何广告！谨防上当受骗</p>
        <p class="indent-2-tabs">
          2.暂时只开放一个解析接口(其他接口站均被植入xx广告，待我屏蔽广告后在开放，如发现广告(除影片内部)请马上与我联系。再次提醒请勿相信页面内(包括但不限于)任何广告！谨防上当受骗</p>
        <p class="indent-2-tabs">3.如果视频解析失败或连接失败请更换接口后重试，反馈请联系w0401@foxmail.com</p>
        <p class="indent-2-tabs">4.由于名称搜索可能会搜索出预告等非视频内容，建议使用网址解析，如不知如何使用网址解析请点击网址解析tab中的查看教程</p>
        <p class="indent-2-tabs">5.移动端地址处理爱奇艺地址解析暂时无效，爱奇艺视频请使用电脑端获取地址</p>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="announcement">已知晓</el-button>
        </span>
      </el-dialog>
      <el-dialog title="播放记录" :visible.sync="dialogVideo" width="80%" center 
        :close-on-click-modal="false" :close-on-press-escape="false">
        <p class="indent-2-tabs">{{ this.playRecord }}</p>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="jumpPlay">立即播放</el-button>
        </span>
      </el-dialog>
      <div
        style="display: flex; justify-content: center; padding-top: 5px; background-image: linear-gradient(to right, #ff8a00, #da1b60); color: #ffffff;"
        v-if="isPlay">{{ playName }}
      </div>
      <div v-if="postStatus" style="width: 100%;">
        <iframe :src="postUrl" frameborder="0" class="iframe-content"></iframe>
      </div>
      <div>
        <div v-if="chimeeUrl != ''" id="wrapper"></div>
      </div>
      <div class="tab-container">
        <el-tabs class="eltabs" v-model="activeName" type="border-card" @tab-click="handleClick">
          <el-tab-pane label="影视剧" name="TVseries">
            <el-collapse v-model="film" accordion>
              <el-collapse-item title="片头片尾设置" name="film">
                <div v-if="tvJX" style="margin-top: 10px;">
                  <el-time-select v-model="filmTop" :picker-options="{ start: '00:00', step: '00:01', end: '05:00' }"
                    placeholder="片头时长" style="width: 100%;">
                  </el-time-select>
                </div>
                <div v-if="tvJX" style="margin-top: 10px;">
                  <el-time-select v-model="filmBottom" :picker-options="{ start: '00:00', step: '00:01', end: '05:00' }"
                    placeholder="片尾时长" style="width: 100%;">
                  </el-time-select>
                </div>
                <div style="width: 100%;margin-top: 10px;">
                  <el-button type="success" plain style="width: 100%;" @click="filmTopBottom">确认跳过片头片尾</el-button>
                </div>
              </el-collapse-item>
            </el-collapse>
            <div v-if="tvJX" style="margin-top: 10px;">
              <el-input v-model="TVseriesName" placeholder="请输入影视剧名称" style="width: 100%;"></el-input>
            </div>
            <div v-if="tvJX" style="margin-top: 10px; overflow-x: auto;">
              <el-collapse v-if="TVseriesitems && TVseriesitems.length > 0" @change="handleChange(index)" v-model="index" accordion>
                <el-collapse-item v-for="(item, index) in TVseriesitems" :title="item.name + '(' + item.year + '年)'"
                  :name="index" :key="index" v-model="TVseriesitems[index]">
                  <div class="episode-container">
                    <div v-for="(content, cIndex) in item.source" :key="cIndex" class="episode-row">
                      <div v-for="(next, contentIndex) in content" :key="contentIndex" class="episode-column">
                        <el-button @click="handleEpisodeClick(item, next)" :style="{ color: next.play ? 'red' : '' }">{{
                          next.name }}</el-button>
                      </div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div v-if="tvJX" style="width: 100%;margin-top: 10px;">
              <el-button type="success" plain style="width: 100%;" @click="TVseriesNameClick">开始解析</el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'; // axios
import HashUtils from '../assets/hashFunctions.js'; // 加密函数
import Chimee from 'chimee'; // 播放器
import ChimeePlayer from 'chimee-player';
import chimeePluginControlbar from 'chimee-plugin-controlbar'; // 播放器控制条
import chimeePluginPopup from 'chimee-plugin-popup'; // 播放器弹窗
import flv from 'chimee-kernel-flv'; // flv
import hls from 'chimee-kernel-hls'; // hls

export default {
  name: 'App',
  data() {
    return {
      dialogVisible: false,// 公告
      dialogVideo: false,// 播放记录
      isPlay: false, // 是否播放
      chimeeUrl: '', // 播放地址
      activeName: 'TVseries', // 头部折叠面板默认选中
      film: '', // 片头片尾折叠面板
      urlJX: false, // 地址解析
      nameJX: false, // 名称解析
      tvJX: true, // 影视剧解析
      appJX: false, // 移动端地址处理
      skipTopTime: 0, // 跳过的时间，以秒为单位
      skipBottomTime: 0, // 跳过的时间，以秒为单位
      lastStep: '', // 上一集地址
      lastNext: '', // 下一集地址
      filmTop: '', // 片头时长
      filmBottom: '', // 片尾时长
      TVseriesName: '', // 影视剧名称
      TVseriesitems: [], // 影视剧列表
      index: 0, // 当前选中的影视剧
      screen: false, // 是否全屏
      playStatus: true, // 播放状态
      postUrl: 'https://im1907.top/', // iframe地址
      postStatus: false, // 是否显示iframe
      video: {},// 播放记录对象
      playRecord: '',// 播放记录文本
      jumpSatus:false,// 跳过状态
    };
  },

  watch: {
  },
  created() {

  },
  mounted() {
    this.getFilm();
    this.getVideo();
  },
  methods: {
    // 缓存公告已知晓
    announcement() {
      this.dialogVisible = false;
      // 缓存到本地
      localStorage.setItem('announcement', 'true');
    },
    // 获取片头片尾信息
    getFilm() {
      const filmTop = localStorage.getItem('filmTop');
      const filmBottom = localStorage.getItem('filmBottom');
      if (filmTop) {
        this.filmTop = filmTop;
        this.skipTopTime = this.timeStringToSeconds(filmTop);
      }
      if (filmBottom) {
        this.filmBottom = filmBottom;
      }
    },
    // 获取播放缓存
    getVideo() {
      // 从localStorage中读取并转换为对象
      let video = JSON.parse(localStorage.getItem('video'));
      if (video != null) {
        this.playRecord = '经系统检测，您当前存在播放记录：《' + video.select + '-' + video.count + '》，是否立即播放？'
        this.dialogVideo = true;
      }
    },
    async jumpPlay() {
      this.dialogVideo = false;
      let video = JSON.parse(localStorage.getItem('video'));
      this.TVseriesName = video.select;
      this.lastStep = video.lastStep;
      this.lastNext = video.lastNext;
      await this.TVseriesNameClick();
      this.handleChange(video.index);
      this.handleEpisodeClick(undefined,video,'4')
    },
    // 切换头部折叠面板
    handleClick(tab, event) {
      if (this.activeName == 'TVseries') {
        this.$nextTick(() => {
          this.urlJX = false;
          this.nameJX = false;
          this.tvJX = true;
          this.appJX = false;
        });
      }

      if (this.activeName == 'url') {
        this.$nextTick(() => {
          this.urlJX = true;
          this.nameJX = false;
          this.tvJX = false;
          this.appJX = false;
        });
      }
      if (this.activeName == 'name') {
        this.$nextTick(() => {
          this.urlJX = false;
          this.nameJX = true;
          this.tvJX = false;
          this.appJX = false;
        });
      }
      if (this.activeName == 'path') {
        this.$nextTick(() => {
          this.urlJX = false;
          this.nameJX = false;
          this.tvJX = false;
          this.appJX = true;
        });
      }
    },
    // 设置片头片尾
    filmTopBottom() {
      if (this.filmTop == "" || this.filmBottom == "") {
        this.$message.error('请输入片头片尾时长');
        return;
      }
      localStorage.setItem('filmTop', this.filmTop);
      localStorage.setItem('filmBottom', this.filmBottom);
      this.skipTopTime = this.timeStringToSeconds(this.filmTop);
      this.skipBottomTime = this.timeStringToSeconds(this.filmBottom);
      this.$message.success('设置成功');
    },
    // 影视剧解析
    async TVseriesNameClick() {
      let i = new Date();
      let o = i.getTime();
      let a = 6e4 * i.getTimezoneOffset();
      let l = new Date(o + a + 36e5 * 8);
      let s = l;
      s = s.getDate() + 9 + 9 ^ 10;
      s = HashUtils.y(String(s))
      s = s.substring(0, 10),
        s = HashUtils.y(s);
      const response = await axios.get('/TVseriesName/api/v/?z=' + s + '&jx=' + this.TVseriesName);
      // 为每个项添加唯一的 id
      this.TVseriesitems = response.data.data;
      if (!this.TVseriesitems && response.data.type == 4000) {
        this.$message.error('访问频率过高，请滑动滑块验证后再试');
        this.postStatus = true;
      }
    },
    // 点击剧名
    handleChange(val) {
      this.index = val;
    },
    // 点击集数
    handleEpisodeClick(item, next, type) {
      this.getFilm();
      var catalogue = [];
      this.catalogue = [];
      // 首次进入
      if (item && next) {
        console.log("首次");
        item.source.eps.forEach((element, index) => {
          const entry = { 'name': element.name, 'url': element.url, 'play': false };
          if (element.name === next.name) {
            this.lastStep = item.source.eps[index - 1]?.url ?? '';
            this.lastNext = item.source.eps[index + 1]?.url ?? '';
            entry.play = true;
            element.play = true;
          } else {
            element.play = false;
          }
          catalogue.push(entry);
        });
        this.catalogue = catalogue;
        this.chimeeUrl = next.url;
        this.playName = "当前播放的是：" + item.name + '(' + item.year + '年)-' + next.name;
        this.video.count = next.name;
      }
      // 上一集
      if (!item && !next && type === '0') {
        var name = '';
        var url = '';
        this.TVseriesitems[this.index].source.eps.forEach((element, index) => {
          const entry = { 'name': element.name, 'url': element.url, 'play': false };
          if (element.url === this.lastStep) {
            url = element.url;
            this.lastStep = this.TVseriesitems[this.index].source.eps[index - 1]?.url ?? '';
            this.next = this.TVseriesitems[this.index].source.eps[index + 1]?.url ?? '';
            entry.play = true;
            element.play = true;
            name = element.name;
          } else {
            entry.play = false;
            element.play = false;
          }
          catalogue.push(entry);
        });
        this.chimee.currentTime = this.skipTopTime;
        this.catalogue = catalogue;
        this.chimeeUrl = url;
        this.playName = "当前播放的是：" + this.TVseriesitems[this.index].name + '(' + this.TVseriesitems[this.index].year + '年)-' + name;
        this.video.count = name;
      }
      // 下一集
      if (!item && !next && type === '1') {
        var name = '';
        var url = '';
        var lastNext = '';
        this.TVseriesitems[this.index].source.eps.forEach((element, index) => {
          const entry = { 'name': element.name, 'url': element.url, 'play': false };
          if (element.url == this.lastNext) {
            url = element.url;
            this.lastStep = this.TVseriesitems[this.index].source.eps[index - 1]?.url ?? '';
            lastNext = this.TVseriesitems[this.index].source.eps[index + 1]?.url ?? '';
            entry.play = true;
            element.play = true;
            name = element.name;
          } else {
            entry.play = false;
            element.play = false;
          }
          catalogue.push(entry);
        });
        this.catalogue = catalogue;
        this.chimeeUrl = url;
        this.lastNext = lastNext;
        this.playName = "当前播放的是：" + this.TVseriesitems[this.index].name + '(' + this.TVseriesitems[this.index].year + '年)-' + name;
        this.video.count = name;
      }
      // 点击集数
      if (!item && next && type === '3') {
        var name = '';
        var url = '';
        this.TVseriesitems[this.index].source.eps.forEach((element, index) => {
          const entry = { 'name': element.name, 'url': element.url, 'play': false };
          if (element.url == next.url) {
            url = element.url;
            this.lastStep = this.TVseriesitems[this.index].source.eps[index - 1]?.url ?? '';
            this.lastNext = this.TVseriesitems[this.index].source.eps[index + 1]?.url ?? '';
            entry.play = true;
            element.play = true;
            name = element.name;
          } else {
            entry.play = false;
            element.play = false;
          }
          catalogue.push(entry);
        });
        this.catalogue = catalogue;
        this.chimeeUrl = url;
        this.playName = "当前播放的是：" + this.TVseriesitems[this.index].name + '(' + this.TVseriesitems[this.index].year + '年)-' + name;
        this.video.count = name;
      }
      if(!item && next && type === '4'){
        var name = '';
        var url = '';
        this.TVseriesitems[this.index].source.eps.forEach((element, index) => {
          const entry = { 'name': element.name, 'url': element.url, 'play': false };
          if (element.name == next.count) {
            url = element.url;
            this.lastStep = this.TVseriesitems[this.index].source.eps[index - 1]?.url ?? '';
            this.lastNext = this.TVseriesitems[this.index].source.eps[index + 1]?.url ?? '';
            entry.play = true;
            element.play = true;
            name = element.name;
          } else {
            entry.play = false;
            element.play = false;
          }
          catalogue.push(entry);
        });
        this.catalogue = catalogue;
        this.chimeeUrl = url;
        this.skipTopTime = next.time;
        this.playName = "当前播放的是：" + this.TVseriesitems[this.index].name + '(' + this.TVseriesitems[this.index].year + '年)-' + name;
        this.video.count = name;
      }
      this.chimeeUrl = next.url;
      this.$nextTick(() => {
        this.chimeeInit();
      });
      this.isPlay = true;
    },
    // 播放器初始化
    chimeeInit() {
      if (this.chimee) {
        this.chimee.destroy();
      }
      this.chimee = new ChimeePlayer({
        wrapper: '#wrapper',
        src: this.chimeeUrl,
        box: 'hls',
        autoplay: true,
        plugins: [{
          name: chimeePluginControlbar.name,
          children: {
            play: {},//播放按钮
            progressTime: {},//时间
            progressBar: {},//进度条
            volume: {},//声音
            // playbackrate: {
            //   list: [
            //     { name: '0.5', value: 0.5 },
            //     { name: '1.0', value: 1, default: true },
            //     { name: '2.0', value: 2 }
            //   ]
            // },
            prev: this.lastStep !== '' ? {
              tag: '上集',
              html: `<span style="font-size: 16px; color: #FFFFFF;margin-right: 20px;">上集</span>`,
              event: {
                click: () => {
                  this.handleEpisodeClick(undefined, undefined, '0');
                }
              },
            } : { tag: '上集', html: `` },
            catalogue: this.catalogue.length > 0 ? {
              tag: '剧集',
              html: `<span style="font-size: 16px; color: #FFFFFF;margin-right: 20px;">剧集</span>`,
              event: {
                click: () => {
                  if (this.chimee.$plugins.ccPopup) {
                    // 如果已经启用，则停用插件
                    this.chimee.unuse(this.popupPluginConfig.name);
                  } else {
                    this.popupPluginConfig = {
                      name: 'cc_popup',
                      title: '剧集列表',
                      html: `<div style="display: flex; flex-wrap: wrap; justify-content: space-between; overflow-y: scroll; height: 400px;">` +
                        this.catalogue.map((item, index) => `
          <div style="width: calc(20% - 10px); margin: 5px; word-break: break-word; font-size: 16px;">
            <button style="
              width: 80px;
              padding: 10px 10px;
              font-size: 16px;
              background-color: #007BFF;
              color: white;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              transition: background-color 0.3s ease;
              ${item.play ? 'background-color: red;' : ''}
            ">${item.name}</button>
          </div>
        `).join('') +
                        `</div>`,
                      offset: '50% 50%',
                      width: '800px',
                      data: {
                        text: 'play'
                      },
                      methods: {
                        changeVideoStatus: (clickedButton) => {
                          this.catalogue.forEach((item, index) => {
                            if (item.name === clickedButton.innerText) {
                              this.handleEpisodeClick(undefined, item, '3');
                            }
                          });
                        },
                      },
                      create() {
                        this.$dom.addEventListener('click', (event) => {
                          this.changeVideoStatus(event.target);
                        });
                      },
                      events: {
                      }

                      // height: '100px',
                    };
                    //安装插件
                    ChimeePlayer.install(chimeePluginPopup(this.popupPluginConfig));
                    //使用插件
                    this.chimee.use('cc_popup');
                  }
                }
              },
            } : { tag: '剧集', html: `` },
            next: this.next !== '' ? {
              tag: '下集',
              html: `<span style="font-size: 16px; color: #FFFFFF;margin-right: 20px;">下集</span>`,
              event: {
                click: () => {
                  this.handleEpisodeClick(undefined, undefined, '1');
                }
              },
            } : { tag: '下集', html: `` },
            screen: {
              event: {
                click: () => {
                  if (!this.screen) {
                    this.chimee.$fullscreen();
                  } else {
                    this.chimee.$fullscreen(false);;
                  }
                  this.screen = !this.screen;
                }
              },
            },
          }
        }],
        controls: true,
      });

      // 记录当前查询视频数据
      this.video.select = this.TVseriesName
      // 记录当前点击的剧名
      this.video.index = this.index
      // 记录当前播放视频
      this.video.url = this.chimeeUrl
      // 记录上一集
      this.video.lastStep = this.lastStep;
      // 记录下一集
      this.video.lastNext = this.lastNext;
      // 将对象转换为JSON字符串并存储
      localStorage.setItem('video', JSON.stringify(this.video));


      this.chimee.on('timeupdate', (event) => {
        if(!this.jumpSatus){
          // 设置跳过片头
          this.chimee.currentTime = this.skipTopTime;
          this.jumpSatus = true;
        }
        // 总时间
        const totalTime = this.chimee.duration;
        // 片尾时间
        const filmBottom = this.timeStringToSeconds(this.filmBottom)
        // 获取当前播放时间
        const currentTime = this.chimee.currentTime;
        // 向本地缓存记录当前播放位置
        this.video.time = currentTime
        // 将对象转换为JSON字符串并存储
        localStorage.setItem('video', JSON.stringify(this.video));
        if (totalTime - currentTime <= filmBottom) {
          this.handleEpisodeClick(undefined, undefined, '1');// 下一集
        }
      });
    },
    // 时间转换
    timeStringToSeconds(timeString) {
      const parts = timeString.split(':');
      const length = parts.length;

      let seconds = 0;
      let multiplier = 1;

      for (let i = length - 1; i >= 0; i--) {
        seconds += parseInt(parts[i]) * multiplier;
        multiplier *= 60;
      }

      return seconds;
    },
  }
}
</script>

<style scoped>
.indent-1-tabs {
  text-indent: 1em;
  font-size: 20px;
  color: #ff0000;
  /* 或者使用其他你想要的缩进宽度 */
}

.indent-2-tabs {
  text-indent: 2em;
  font-size: 16px;
  color: #000;
  /* 或者使用其他你想要的缩进宽度 */
}

.background {
  min-height: 100vh;
  background-image: url('~@/assets/background-win.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.block {
  width: 100%;
  height: 3vh;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* 允许元素换行 */
}

/* .iframe-container, */
.tab-container {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.iframe-container {
  /* height: 80vh; */
}

.iframe-content {
  width: 100%;
  max-width: 100%;
  height: 80vh;
}

.msgPrompt {
  max-width: 100%;
  margin: auto;
  margin-top: 10px;
}

.eltabs {
  margin-bottom: 20px;
}

.el-tabs--border-card {
  width: 98%;
}

.episode-container {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto
}

.episode-row {
  display: flex;
  margin-bottom: 10px;
}

.episode-column {
  margin-right: 10px;
  /* 设置按钮之间的水平间距 */
}

.el-button {
  flex-shrink: 0;
  /* 阻止按钮缩小以适应容器 */
}

/* 响应式布局的媒体查询 */
@media (max-width: 768px) {
  .background {
    /* 对于移动设备，可能需要调整背景固定效果 */
    background-attachment: scroll;
  }

  .iframe-container,
  .tab-container,
  .msgPrompt,
  .el-tabs--border-card {
    /* 对于小屏幕，宽度调整为100%以利用可用空间 */
    width: 100%;
    max-width: 100%;
    /* 移除宽度限制 */
  }

  .el-tabs--border-card {
    width: 98%;
  }

  /* .iframe-content {
      height: 80vh;
    } */


  .block {
    /* 减小移动设备上的高度 */
    height: auto;
    /* padding: 10px; */
    /* 添加一些内边距确保不会太拥挤 */
  }

  .block>.el-link {
    /* 减小元素之间的间距 */
    margin-left: 5px;
    margin-bottom: 5px;
    /* 添加底部边距来避免重叠 */
    font-size: 14px;
    /* 减小字体大小 */
  }

  .block .el-tooltip .el-link {
    /* 特殊处理包含工具提示的链接 */
    margin-left: 5px;
    margin-right: 5px;
    /* 确保链接之间有间隔 */
  }
}
</style>

<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}
</style>