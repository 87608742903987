<template>
  <div>
    <div class="background">
      <div class="block">
        <el-link href="https://www.tianzi.info/" target="_blank" type="primary">天紫导航</el-link>
        <el-link href="https://blog.tianzi.info/" target="_blank" type="primary"
          style="margin-left: 10px;">天紫论坛</el-link>
        <el-link href="https://img.tianzi.info/" target="_blank" type="primary"
          style="margin-left: 10px;">天紫图床</el-link>
        <el-link href="https://jz.tianzi.info/" target="_blank" type="primary" style="margin-left: 10px;">天紫记账</el-link>
        <el-tooltip placement="top">
          <template slot="content">
            <img src="~@/assets/er.jpg" alt="WeChat" style="width: 20vw; height: auto;" />
          </template>
          <el-link type="primary" style="margin-left: 10px;">联系天紫</el-link>
        </el-tooltip>
        <el-tooltip placement="top">
          <template slot="content">
            <img src="~@/assets/sk.png" alt="WeChat" style="width: 20vw; height: auto;" />
          </template>
          <el-link type="danger" style="margin-top: 5px;">是不是应该整点广告？ - -!开玩笑的。- -。求赞助~</el-link>
        </el-tooltip>
      </div>


      <el-dialog title="公告" :visible.sync="dialogVisible" width="80%" center :show-close="false"
        :close-on-click-modal="false" :close-on-press-escape="false">
        <p class="indent-1-tabs">本公告只会在首次进入网站时进行提示，请务必仔细阅读。</p>
        <p class="indent-2-tabs">1.本站所有解析接口均来自互联网，旨在交流学习使用。请勿相信页面内(包括但不限于)任何广告！谨防上当受骗</p>
        <p class="indent-2-tabs">
          2.暂时只开放一个解析接口(其他接口站均被植入xx广告，待我屏蔽广告后在开放，如发现广告(除影片内部)请马上与我联系。再次提醒请勿相信页面内(包括但不限于)任何广告！谨防上当受骗</p>
        <p class="indent-2-tabs">3.如果视频解析失败或连接失败请更换接口后重试，反馈请联系w0401@foxmail.com</p>
        <p class="indent-2-tabs">4.由于名称搜索可能会搜索出预告等非视频内容，建议使用网址解析，如不知如何使用网址解析请点击网址解析tab中的查看教程</p>
        <p class="indent-2-tabs">5.移动端地址处理爱奇艺地址解析暂时无效，爱奇艺视频请使用电脑端获取地址</p>
        <span slot="footer">
          <el-button type="primary" @click="announcement">已知晓</el-button>
        </span>
      </el-dialog>

      <el-dialog title="播放记录" :visible.sync="dialogVideo" width="80%" center 
        :close-on-click-modal="false" :close-on-press-escape="false">
        <p class="indent-2-tabs">{{ this.playRecord }}</p>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="jumpPlay">立即播放</el-button>
        </span>
      </el-dialog>


      <div
        style="display: flex; justify-content: center; padding-top: 5px; background-image: linear-gradient(to right, #ff8a00, #da1b60); color: #ffffff;"
        v-if="isPlay">{{ playName }}
      </div>
      <div v-if="postStatus" style="width: 100%;">
        <iframe :src="postUrl" frameborder="0" class="iframe-content"></iframe>
      </div>
      <div>
        <div v-if="chimeeUrl != ''" id="wrapper"></div>
      </div>

      <div class="tab-container">
        <el-tabs class="eltabs" v-model="activeName" type="border-card" @tab-click="handleClick">
          <el-tab-pane label="影视剧" name="TVseries">
            <el-collapse v-model="film" accordion>
              <el-collapse-item title="片头片尾设置" name="film">
                <div v-if="tvJX" style="margin-top: 10px;">
                  <el-time-select v-model="filmTop" :picker-options="{ start: '00:00', step: '00:01', end: '05:00' }"
                    placeholder="片头时长" style="width: 100%;">
                  </el-time-select>
                </div>
                <div v-if="tvJX" style="margin-top: 10px;">
                  <el-time-select v-model="filmBottom" :picker-options="{ start: '00:00', step: '00:01', end: '05:00' }"
                    placeholder="片尾时长" style="width: 100%;">
                  </el-time-select>
                </div>
                <div style="width: 100%;margin-top: 10px;">
                  <el-button type="success" plain style="width: 100%;" @click="filmTopBottom">确认跳过片头片尾</el-button>
                </div>
              </el-collapse-item>
            </el-collapse>
            <div v-if="tvJX" style="margin-top: 10px;">
              <el-input v-model="TVseriesName" placeholder="请输入影视剧名称" style="width: 100%;"></el-input>
            </div>
            <div v-if="tvJX" style="margin-top: 10px; overflow-x: auto;">
              <el-collapse v-if="TVseriesitems && TVseriesitems.length > 0" @change="handleChange(index)" v-model="index" accordion>
                <el-collapse-item v-for="(item, index) in TVseriesitems" :title="item.name + '(' + item.year + '年)'"
                  :name="index" :key="index" v-model="TVseriesitems[index]">
                  <div class="episode-container">
                    <div v-for="(content, cIndex) in item.source" :key="cIndex" class="episode-row">
                      <div v-for="(next, contentIndex) in content" :key="contentIndex" class="episode-column">
                        <el-button @click="handleEpisodeClick(item, next)" :style="{ color: next.play ? 'red' : '' }">{{
                          next.name }}</el-button>
                      </div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div v-if="tvJX" style="width: 100%;margin-top: 10px;">
              <el-button type="success" plain style="width: 100%;" @click="TVseriesNameClick">开始解析</el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'; // axios
import HashUtils from '../assets/hashFunctions.js'; // 加密函数
import chimee from 'chimee'; // chimee播放器
import ChimeeMobilePlayer from 'chimee-mobile-player'; // chimee移动端播放器
import chimeePluginMobileControlbar from 'chimee-plugin-mobile-controlbar'; // chimee移动端播放器控制条
import 'chimee-mobile-player/lib/chimee-mobile-player.browser.css'; // chimee移动端播放器样式
import chimeePluginPopup from 'chimee-plugin-popup'; // chimee播放器弹窗
import 'chimee-plugin-mobile-state/lib/index.browser.js'; // chimee移动端播放器样式
export default {
  name: 'App',
  data() {
    return {
      dialogVisible: false,// 公告
      dialogVideo: false,// 播放记录
      isPlay: false, // 是否播放
      chimeeUrl: '', // 播放地址
      activeName: 'TVseries', // 头部折叠面板默认选中
      film: '', // 片头片尾折叠面板
      urlJX: false, // 地址解析
      nameJX: false, // 名称解析
      tvJX: true, // 影视剧解析
      appJX: false, // 移动端地址处理
      skipTopTime: 0, // 跳过的时间，以秒为单位
      skipBottomTime: 0, // 跳过的时间，以秒为单位
      lastStep: '', // 上一集地址
      lastNext: '', // 下一集地址
      filmTop: '', // 片头时长
      filmBottom: '', // 片尾时长
      TVseriesName: '', // 影视剧名称
      TVseriesitems: [], // 影视剧列表
      index: 0, // 当前选中的影视剧
      screen: false, // 是否全屏
      postUrl: 'https://im1907.top/', // iframe地址
      postStatus: false, // 是否显示iframe
      video: {},// 播放记录对象
      playRecord: '',// 播放记录文本
      jumpSatus:false,// 跳过状态
    };
  },
  watch: {

  },
  created() {

  },
  mounted() {
    this.getFilm();
    this.getVideo();
  },
  methods: {
    // 缓存公告已知晓
    announcement() {
      this.dialogVisible = false;
      // 缓存到本地
      localStorage.setItem('announcement', 'true');
    },
    // 获取片头片尾信息
    getFilm() {
      const filmTop = localStorage.getItem('filmTop');
      const filmBottom = localStorage.getItem('filmBottom');
      if (filmTop) {
        this.filmTop = filmTop;
        this.skipTopTime = this.timeStringToSeconds(filmTop);
      }
      if (filmBottom) {
        this.filmBottom = filmBottom;
      }
    },
    // 获取播放缓存
    getVideo() {
      // 从localStorage中读取并转换为对象
      let video = JSON.parse(localStorage.getItem('video'));
      console.log(video);
      if (video != null) {
        this.playRecord = '经系统检测，您当前存在播放记录：《' + video.select + '-' + video.count + '》，是否立即播放？'
        this.dialogVideo = true;
      }
    },
    async jumpPlay() {
      this.dialogVideo = false;
      let video = JSON.parse(localStorage.getItem('video'));
      this.TVseriesName = video.select;
      this.lastStep = video.lastStep;
      this.lastNext = video.lastNext;
      await this.TVseriesNameClick();
      this.handleChange(video.index);
      this.handleEpisodeClick(undefined,video,'4')
    },
    // 切换头部折叠面板
    handleClick(tab, event) {
      if (this.activeName == 'TVseries') {
        this.$nextTick(() => {
          this.urlJX = false;
          this.nameJX = false;
          this.tvJX = true;
          this.appJX = false;
        });
      }

      if (this.activeName == 'url') {
        this.$nextTick(() => {
          this.urlJX = true;
          this.nameJX = false;
          this.tvJX = false;
          this.appJX = false;
        });
      }
      if (this.activeName == 'name') {
        this.$nextTick(() => {
          this.urlJX = false;
          this.nameJX = true;
          this.tvJX = false;
          this.appJX = false;
        });
      }
      if (this.activeName == 'path') {
        this.$nextTick(() => {
          this.urlJX = false;
          this.nameJX = false;
          this.tvJX = false;
          this.appJX = true;
        });
      }
    },
    // 设置片头片尾
    filmTopBottom() {
      if (this.filmTop == "" || this.filmBottom == "") {
        this.$message.error('请输入片头片尾时长');
        return;
      }
      localStorage.setItem('filmTop', this.filmTop);
      localStorage.setItem('filmBottom', this.filmBottom);
      this.skipTopTime = this.timeStringToSeconds(this.filmTop);
      this.skipBottomTime = this.timeStringToSeconds(this.filmBottom);
      this.$message.success('设置成功');
    },
    // 影视剧解析
    async TVseriesNameClick() {
      let i = new Date();
      let o = i.getTime();
      let a = 6e4 * i.getTimezoneOffset();
      let l = new Date(o + a + 36e5 * 8);
      let s = l;
      s = s.getDate() + 9 + 9 ^ 10;
      s = HashUtils.y(String(s))
      s = s.substring(0, 10),
        s = HashUtils.y(s);
      const response = await axios.get('/TVseriesName/api/v/?z=' + s + '&jx=' + this.TVseriesName);
      // 为每个项添加唯一的 id
      this.TVseriesitems = response.data.data;
      if (!this.TVseriesitems && response.data.type == 4000) {
        this.$message.error('访问频率过高，请滑动滑块验证后再试');
        this.postStatus = true;
      }
    },
    // 点击剧名
    handleChange(val) {
      this.index = val;
    },
    // 点击集数
    handleEpisodeClick(item, next, type) {
      this.getFilm();
      var catalogue = [];
      this.catalogue = [];
      // 首次进入
      if (item && next) {
        item.source.eps.forEach((element, index) => {
          const entry = { 'name': element.name, 'url': element.url, 'play': false };
          if (element.name === next.name) {
            this.lastStep = item.source.eps[index - 1]?.url ?? '';
            this.lastNext = item.source.eps[index + 1]?.url ?? '';
            entry.play = true;
            element.play = true;
          } else {
            element.play = false;
          }
          catalogue.push(entry);
        });
        this.catalogue = catalogue;
        this.chimeeUrl = next.url;
        this.playName = "当前播放的是：" + item.name + '(' + item.year + '年)-' + next.name;
        this.video.count = next.name;
      }
      // 上一集
      if (!item && !next && type === '0') {
        var name = '';
        var url = '';
        this.TVseriesitems[this.index].source.eps.forEach((element, index) => {
          const entry = { 'name': element.name, 'url': element.url, 'play': false };
          if (element.url === this.lastStep) {
            url = element.url;
            this.lastStep = this.TVseriesitems[this.index].source.eps[index - 1]?.url ?? '';
            this.next = this.TVseriesitems[this.index].source.eps[index + 1]?.url ?? '';
            entry.play = true;
            element.play = true;
            name = element.name;
          } else {
            entry.play = false;
            element.play = false;
          }
          catalogue.push(entry);
        });
        this.chimee.currentTime = this.skipTopTime;
        this.catalogue = catalogue;
        this.chimeeUrl = url;
        this.playName = "当前播放的是：" + this.TVseriesitems[this.index].name + '(' + this.TVseriesitems[this.index].year + '年)-' + name;
        this.video.count = name;
      }
      // 下一集
      if (!item && !next && type === '1') {
        var name = '';
        var url = '';
        var lastNext = '';
        this.TVseriesitems[this.index].source.eps.forEach((element, index) => {
          const entry = { 'name': element.name, 'url': element.url, 'play': false };
          if (element.url == this.lastNext) {
            url = element.url;
            this.lastStep = this.TVseriesitems[this.index].source.eps[index - 1]?.url ?? '';
            lastNext = this.TVseriesitems[this.index].source.eps[index + 1]?.url ?? '';
            entry.play = true;
            element.play = true;
            name = element.name;
          } else {
            entry.play = false;
            element.play = false;
          }
          catalogue.push(entry);
        });
        this.catalogue = catalogue;
        this.chimeeUrl = url;
        this.lastNext = lastNext;
        this.playName = "当前播放的是：" + this.TVseriesitems[this.index].name + '(' + this.TVseriesitems[this.index].year + '年)-' + name;
        this.video.count = name;
      }
      // 点击集数
      if (!item && next && type === '3') {
        var name = '';
        var url = '';
        this.TVseriesitems[this.index].source.eps.forEach((element, index) => {
          const entry = { 'name': element.name, 'url': element.url, 'play': false };
          if (element.url == next.url) {
            url = element.url;
            this.lastStep = this.TVseriesitems[this.index].source.eps[index - 1]?.url ?? '';
            this.lastNext = this.TVseriesitems[this.index].source.eps[index + 1]?.url ?? '';
            entry.play = true;
            element.play = true;
            name = element.name;
          } else {
            entry.play = false;
            element.play = false;
          }
          catalogue.push(entry);
        });
        this.catalogue = catalogue;
        this.chimeeUrl = url;
        this.playName = "当前播放的是：" + this.TVseriesitems[this.index].name + '(' + this.TVseriesitems[this.index].year + '年)-' + name;
        this.video.count = name;
      }
      if(!item && next && type === '4'){
        var name = '';
        var url = '';
        this.TVseriesitems[this.index].source.eps.forEach((element, index) => {
          const entry = { 'name': element.name, 'url': element.url, 'play': false };
          if (element.name == next.count) {
            url = element.url;
            this.lastStep = this.TVseriesitems[this.index].source.eps[index - 1]?.url ?? '';
            this.lastNext = this.TVseriesitems[this.index].source.eps[index + 1]?.url ?? '';
            entry.play = true;
            element.play = true;
            name = element.name;
          } else {
            entry.play = false;
            element.play = false;
          }
          catalogue.push(entry);
        });
        this.catalogue = catalogue;
        this.chimeeUrl = url;
        this.skipTopTime = next.time;
        this.playName = "当前播放的是：" + this.TVseriesitems[this.index].name + '(' + this.TVseriesitems[this.index].year + '年)-' + name;
        this.video.count = name;
      }
      this.$nextTick(() => {
        this.chimeeInit();
      });
      this.isPlay = true;
    },
    // 播放器初始化
    chimeeInit() {
      if (this.chimee) {
        this.chimee.destroy();
      }
      // chimee.install(chimeePluginMobileState);
      this.chimee = new ChimeeMobilePlayer({
        wrapper: '#wrapper',  // video dom容器
        src: this.chimeeUrl,  // 视频地址
        autoplay: true,
        controls: true,
        playsInline: true,
        preload: 'auto',
        plugin: [{
          name: chimeePluginMobileControlbar.name, // 或者 'chimeeControl'
          children: {
            play: {},//播放按钮
            currentTime: {},//时间
            totalTime: {},//总时间
            progressBar: {},//进度条
            prev: this.lastStep !== '' ? {
              tag: '上集', html: `<span style="font-size: 16px; color: #FFFFFF;margin-right: 20px;">上集</span>`,
              event: {
                tap: () => {
                  this.handleEpisodeClick(undefined, undefined, '0');
                },
              },
            } : { tag: '上集', html: `` },
            catalogue: this.catalogue.length > 0 ? {
              tag: '剧集',
              html: `<span style="font-size: 16px; color: #FFFFFF;margin-right: 20px;">剧集</span>`,
              event: {
                tap: () => {
                  if (this.chimee.$plugins.ccPopup) {
                    // 如果已经启用，则停用插件
                    this.chimee.unuse(this.popupPluginConfig.name);
                  } else {
                    this.popupPluginConfig = {
                      name: 'cc_popup',
                      title: '剧集列表',
                      html: `<div style="display: flex; flex-wrap: wrap; justify-content: space-between; overflow-y: scroll; height: 150px;">` +
                        this.catalogue.map((item, index) => `
                        <div style="width: calc(20% - 10px); margin: 5px; word-break: break-word; font-size: 8px;">
                        <button style="width: 50px;padding: 10px 10px;font-size: 8px;background-color: #007BFF;color: white;border: none;border-radius: 5px;cursor: pointer;
                          transition: background-color 0.3s ease;
                          ${item.play ? 'background-color: red;' : ''}
                          ">${item.name}</button>
                        </div>
                        `).join('') +
                        `</div>`,
                      offset: '50% 50%',
                      width: '300px',
                      data: {
                        text: 'play'
                      },
                      methods: {
                        changeVideoStatus: (clickedButton) => {
                          this.catalogue.forEach((item, index) => {
                            if (item.name === clickedButton.innerText) {
                              this.handleEpisodeClick(undefined, item, '3');
                            }
                          });
                        },
                      },
                      create() {
                        this.$dom.addEventListener('click', (event) => {
                          this.changeVideoStatus(event.target);
                        });
                      },
                      events: {
                      }
                    };
                    //安装插件
                    ChimeeMobilePlayer.install(chimeePluginPopup(this.popupPluginConfig));
                    //使用插件
                    this.chimee.use('cc_popup');
                  }
                },
              },
            } : { tag: '剧集', html: `` },
            next: this.next !== '' ? {
              tag: '下集',
              html: `<span style="font-size: 16px; color: #FFFFFF;margin-right: 20px;">下集</span>`,
              event: {
                tap: () => {
                  this.handleEpisodeClick(undefined, undefined, '1');
                },
              },
            } : { tag: '下集', html: `` },
            screen: !this.screen ? {
              tag: '全屏',
              html: `<div style="margin-right: 20px;"> <svg t="1715690223500" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4120" width="200" height="200"><path d="M0 232.732444A232.732444 232.732444 0 0 1 232.732444 0h558.535112A232.732444 232.732444 0 0 1 1024 232.732444v558.535112A232.732444 232.732444 0 0 1 791.267556 1024H232.732444A232.732444 232.732444 0 0 1 0 791.267556V232.732444z m232.732444-139.662222a139.662222 139.662222 0 0 0-139.662222 139.662222v558.535112a139.662222 139.662222 0 0 0 139.662222 139.662222h558.535112a139.662222 139.662222 0 0 0 139.662222-139.662222V232.732444a139.662222 139.662222 0 0 0-139.662222-139.662222H232.732444z" fill="#ffffff" p-id="4121"></path><path d="M549.575111 245.845333c0-25.799111 20.935111-46.734222 46.734222-46.734222h116.821334A140.202667 140.202667 0 0 1 853.333333 339.313778v116.821333a46.734222 46.734222 0 0 1-93.468444 0v-116.821333c0-25.827556-20.906667-46.734222-46.734222-46.734222h-116.821334a46.734222 46.734222 0 0 1-46.734222-46.734223zM245.845333 549.546667c25.799111 0 46.734222 20.935111 46.734223 46.734222v116.821333c0 25.827556 20.906667 46.734222 46.734222 46.734222h116.821333a46.734222 46.734222 0 0 1 0 93.468445h-116.821333A140.202667 140.202667 0 0 1 199.111111 713.130667v-116.821334c0-25.799111 20.935111-46.734222 46.734222-46.734222z" fill="#ffffff" p-id="4122"></path></svg></div>`,
              event: {
                tap: () => {
                  const videoElements = document.getElementsByTagName('video');
                  const videoElement = videoElements[0];
                  // 添加全屏状态改变事件监听器
                  if (videoElement == undefined) {
                    this.$message.error('视频还未加载好，请稍后再试');
                    return;
                  }
                  if (this.screen) {
                    videoElement.setAttribute('playsinline', '');
                    this.chimee.$fullscreen(false);
                  } else {
                    // 全屏逻辑 1、移除playsinline属性 2、暂停播放 3、开始播放
                    videoElement.removeAttribute('playsinline');
                    videoElement.pause();
                    videoElement.play();
                    this.chimee.$fullscreen();
                    this.screen = true;
                  }
                }
              }
            } : {
              event: {
                tag: '全屏',
                html: `<div style="margin-right: 20px;"> <svg t="1715690223500" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4120" width="200" height="200"><path d="M0 232.732444A232.732444 232.732444 0 0 1 232.732444 0h558.535112A232.732444 232.732444 0 0 1 1024 232.732444v558.535112A232.732444 232.732444 0 0 1 791.267556 1024H232.732444A232.732444 232.732444 0 0 1 0 791.267556V232.732444z m232.732444-139.662222a139.662222 139.662222 0 0 0-139.662222 139.662222v558.535112a139.662222 139.662222 0 0 0 139.662222 139.662222h558.535112a139.662222 139.662222 0 0 0 139.662222-139.662222V232.732444a139.662222 139.662222 0 0 0-139.662222-139.662222H232.732444z" fill="#ffffff" p-id="4121"></path><path d="M549.575111 245.845333c0-25.799111 20.935111-46.734222 46.734222-46.734222h116.821334A140.202667 140.202667 0 0 1 853.333333 339.313778v116.821333a46.734222 46.734222 0 0 1-93.468444 0v-116.821333c0-25.827556-20.906667-46.734222-46.734222-46.734222h-116.821334a46.734222 46.734222 0 0 1-46.734222-46.734223zM245.845333 549.546667c25.799111 0 46.734222 20.935111 46.734223 46.734222v116.821333c0 25.827556 20.906667 46.734222 46.734222 46.734222h116.821333a46.734222 46.734222 0 0 1 0 93.468445h-116.821333A140.202667 140.202667 0 0 1 199.111111 713.130667v-116.821334c0-25.799111 20.935111-46.734222 46.734222-46.734222z" fill="#ffffff" p-id="4122"></path></svg></div>`,
                tap: () => {
                  const videoElements = document.getElementsByTagName('video');
                  const videoElement = videoElements[0];
                  // 添加全屏状态改变事件监听器
                  if (videoElement == undefined) {
                    this.$message.error('视频还未加载好，请稍后再试');
                    return;
                  }
                  if (this.screen) {
                    videoElement.setAttribute('playsinline', '');
                    this.chimee.$fullscreen(false);
                  } else {
                    // 全屏逻辑 1、移除playsinline属性 2、暂停播放 3、开始播放
                    videoElement.removeAttribute('playsinline');
                    videoElement.pause();
                    videoElement.play();
                    this.chimee.$fullscreen();
                    this.screen = true;
                  }
                }
              }
            },
          }
        }]
      });

      // 记录当前查询视频数据
      this.video.select = this.TVseriesName
      // 记录当前点击的剧名
      this.video.index = this.index
      // 记录当前播放视频
      this.video.url = this.chimeeUrl
      // 记录上一集
      this.video.lastStep = this.lastStep;
      // 记录下一集
      this.video.lastNext = this.lastNext;
      console.log(this.video);
      // 将对象转换为JSON字符串并存储
      localStorage.setItem('video', JSON.stringify(this.video));
      
      // 设置跳过片头
      this.chimee.currentTime = this.skipTopTime;
      this.chimee.on('timeupdate', (event) => {
        // 总时间
        const totalTime = this.chimee.duration;
        // 片尾时间
        const filmBottom = this.timeStringToSeconds(this.filmBottom)
        // 获取当前播放时间
        const currentTime = this.chimee.currentTime;
        // 向本地缓存记录当前播放位置
        this.video.time = currentTime
        // 将对象转换为JSON字符串并存储
        localStorage.setItem('video', JSON.stringify(this.video));
        // console.log('下一集倒计时:', (totalTime - filmBottom)-currentTime);
        if (totalTime - currentTime <= filmBottom) {
          this.handleEpisodeClick(undefined, undefined, '1');// 下一集
        }
      });
    },
    // 时间转换
    timeStringToSeconds(timeString) {
      const parts = timeString.split(':');
      const length = parts.length;

      let seconds = 0;
      let multiplier = 1;

      for (let i = length - 1; i >= 0; i--) {
        seconds += parseInt(parts[i]) * multiplier;
        multiplier *= 60;
      }

      return seconds;
    },
  }
}
</script>

<style scoped>
.background {
  min-height: 100vh;
  background-image: url('~@/assets/background-win.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.block {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.indent-1-tabs {
  text-indent: 1em;
  font-size: 20px;
  color: #ff0000;
}

.indent-2-tabs {
  text-indent: 2em;
  font-size: 16px;
  color: #000;
}

.tab-container {
  display: flex;
  justify-content: center;
  /* padding-top: 5px; */
}

.eltabs {
  margin-bottom: 20px;
}

.el-tabs--border-card {
  width: 98%;
}

.episode-container {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto
}

.episode-row {
  display: flex;
  margin-bottom: 10px;
}

.episode-column {
  margin-right: 10px;
  /* 设置按钮之间的水平间距 */
}

.el-button {
  flex-shrink: 0;
  /* 阻止按钮缩小以适应容器 */
}
</style>

<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}
</style>