const HashUtils = {
    o: function (e, t) {
        var n = (65535 & e) + (65535 & t);
        return (e >> 16) + (t >> 16) + (n >> 16) << 16 | 65535 & n;
    },
    a: function (e, t, n, r, i, a) {
        var l, s;
        return this.o((l = this.o(this.o(t, e), this.o(r, a))) << (s = i) | l >>> 32 - s, n);
    },
    l: function (e, t, n, r, i, o, l) {
        var that = this;
        return that.a(t & n | ~t & r, e, t, i, o, l);
    },
    s: function (e, t, n, r, i, o, l) {
        var that = this;
        return that.a(t & r | n & ~r, e, t, i, o, l);
    },
    u: function (e, t, n, r, i, o, l) {
        var that = this;
        return that.a(t ^ n ^ r, e, t, i, o, l);
    },
    c: function (e, t, n, r, i, o, l) {
        var that = this;
        return that.a(n ^ (t | ~r), e, t, i, o, l);
    },
    d: function (e, t) {
        var n, r, i, a, d;
        e[t >> 5] |= 128 << t % 32,
            e[14 + (t + 64 >>> 9 << 4)] = t;
        var p = 1732584193
            , f = -271733879
            , m = -1732584194
            , h = 271733878;
        for (n = 0; n < e.length; n += 16)
            r = p,
                i = f,
                a = m,
                d = h,
                p = this.l(p, f, m, h, e[n], 7, -680876936),
                h = this.l(h, p, f, m, e[n + 1], 12, -389564586),
                m = this.l(m, h, p, f, e[n + 2], 17, 606105819),
                f = this.l(f, m, h, p, e[n + 3], 22, -1044525330),
                p = this.l(p, f, m, h, e[n + 4], 7, -176418897),
                h = this.l(h, p, f, m, e[n + 5], 12, 1200080426),
                m = this.l(m, h, p, f, e[n + 6], 17, -1473231341),
                f = this.l(f, m, h, p, e[n + 7], 22, -45705983),
                p = this.l(p, f, m, h, e[n + 8], 7, 1770035416),
                h = this.l(h, p, f, m, e[n + 9], 12, -1958414417),
                m = this.l(m, h, p, f, e[n + 10], 17, -42063),
                f = this.l(f, m, h, p, e[n + 11], 22, -1990404162),
                p = this.l(p, f, m, h, e[n + 12], 7, 1804603682),
                h = this.l(h, p, f, m, e[n + 13], 12, -40341101),
                m = this.l(m, h, p, f, e[n + 14], 17, -1502002290),
                p = this.s(p, f = this.l(f, m, h, p, e[n + 15], 22, 1236535329), m, h, e[n + 1], 5, -165796510),
                h = this.s(h, p, f, m, e[n + 6], 9, -1069501632),
                m = this.s(m, h, p, f, e[n + 11], 14, 643717713),
                f = this.s(f, m, h, p, e[n], 20, -373897302),
                p = this.s(p, f, m, h, e[n + 5], 5, -701558691),
                h = this.s(h, p, f, m, e[n + 10], 9, 38016083),
                m = this.s(m, h, p, f, e[n + 15], 14, -660478335),
                f = this.s(f, m, h, p, e[n + 4], 20, -405537848),
                p = this.s(p, f, m, h, e[n + 9], 5, 568446438),
                h = this.s(h, p, f, m, e[n + 14], 9, -1019803690),
                m = this.s(m, h, p, f, e[n + 3], 14, -187363961),
                f = this.s(f, m, h, p, e[n + 8], 20, 1163531501),
                p = this.s(p, f, m, h, e[n + 13], 5, -1444681467),
                h = this.s(h, p, f, m, e[n + 2], 9, -51403784),
                m = this.s(m, h, p, f, e[n + 7], 14, 1735328473),
                p = this.u(p, f = this.s(f, m, h, p, e[n + 12], 20, -1926607734), m, h, e[n + 5], 4, -378558),
                h = this.u(h, p, f, m, e[n + 8], 11, -2022574463),
                m = this.u(m, h, p, f, e[n + 11], 16, 1839030562),
                f = this.u(f, m, h, p, e[n + 14], 23, -35309556),
                p = this.u(p, f, m, h, e[n + 1], 4, -1530992060),
                h = this.u(h, p, f, m, e[n + 4], 11, 1272893353),
                m = this.u(m, h, p, f, e[n + 7], 16, -155497632),
                f = this.u(f, m, h, p, e[n + 10], 23, -1094730640),
                p = this.u(p, f, m, h, e[n + 13], 4, 681279174),
                h = this.u(h, p, f, m, e[n], 11, -358537222),
                m = this.u(m, h, p, f, e[n + 3], 16, -722521979),
                f = this.u(f, m, h, p, e[n + 6], 23, 76029189),
                p = this.u(p, f, m, h, e[n + 9], 4, -640364487),
                h = this.u(h, p, f, m, e[n + 12], 11, -421815835),
                m = this.u(m, h, p, f, e[n + 15], 16, 530742520),
                p = this.c(p, f = this.u(f, m, h, p, e[n + 2], 23, -995338651), m, h, e[n], 6, -198630844),
                h = this.c(h, p, f, m, e[n + 7], 10, 1126891415),
                m = this.c(m, h, p, f, e[n + 14], 15, -1416354905),
                f = this.c(f, m, h, p, e[n + 5], 21, -57434055),
                p = this.c(p, f, m, h, e[n + 12], 6, 1700485571),
                h = this.c(h, p, f, m, e[n + 3], 10, -1894986606),
                m = this.c(m, h, p, f, e[n + 10], 15, -1051523),
                f = this.c(f, m, h, p, e[n + 1], 21, -2054922799),
                p = this.c(p, f, m, h, e[n + 8], 6, 1873313359),
                h = this.c(h, p, f, m, e[n + 15], 10, -30611744),
                m = this.c(m, h, p, f, e[n + 6], 15, -1560198380),
                f = this.c(f, m, h, p, e[n + 13], 21, 1309151649),
                p = this.c(p, f, m, h, e[n + 4], 6, -145523070),
                h = this.c(h, p, f, m, e[n + 11], 10, -1120210379),
                m = this.c(m, h, p, f, e[n + 2], 15, 718787259),
                f = this.c(f, m, h, p, e[n + 9], 21, -343485551),
                p = this.o(p, r),
                f = this.o(f, i),
                m = this.o(m, a),
                h = this.o(h, d);
        return [p, f, m, h]
    },
    p: function (e) {
        var t, n = "", r = 32 * e.length;
        for (t = 0; t < r; t += 8)
            n += String.fromCharCode(e[t >> 5] >>> t % 32 & 255);
        return n
    },
    f: function (e) {
        var t, n = [];
        for (n[(e.length >> 2) - 1] = void 0, t = 0; t < n.length; t += 1) {
            n[t] = 0;
        }
        var r = 8 * e.length;
        for (t = 0; t < r; t += 8) {
            n[t >> 5] |= (255 & e.charCodeAt(t / 8)) << t % 32;
        }
        return n;
    },
    m: function (e) {
        var t, n, r = "0123456789abcdef", i = "";
        for (n = 0; n < e.length; n += 1)
            t = e.charCodeAt(n),
                i += r.charAt(t >>> 4 & 15) + r.charAt(15 & t);
        return i
    },
    h: function (e) {
        return decodeURIComponent(encodeURIComponent(e));
    },
    g: function (e) {
        var that = this;
        return function (e) {
            return that.p(that.d(that.f(e), 8 * e.length));
        }(this.h(e));
    },
    v: function (e, t) {
        return function (e, t) {
            var n, r, i = f(e), o = [], a = [];
            for (o[15] = a[15] = void 0,
                i.length > 16 && (i = this.d(i, 8 * e.length)),
                n = 0; n < 16; n += 1)
                o[n] = 909522486 ^ i[n],
                    a[n] = 1549556828 ^ i[n];
            return r = this.d(o.concat(f(t)), 512 + 8 * t.length),
                p(d(a.concat(r), 640))
        }(this.h(e), this.h(t))
    },
    y: function (e, t, n) {
        return t ? n ? this.v(t, e) : this.m(this.v(t, e)) : n ? this.g(e) : this.m(this.g(e))
    },
};

export default HashUtils;